<template>
  <div class="withdrawal_status">
    <main>
      <van-steps direction="vertical" :active="active" active-color="#FB4E10">
        <van-step>
          <h3>发起提现申请</h3>
          <p v-if="info && info.createdAt">{{ info.createdDate }}</p>
          <template #active-icon>
            <svg-icon icon-class="sq1_icon" />
          </template>
          <template #finish-icon>
            <svg-icon icon-class="sq1_icon" />
          </template>
        </van-step>
        <van-step>
          <h3>提现处理中</h3>
          <p v-if="info && info.finishedAt">{{ info.finishedDate }}</p>
          <template #active-icon>
            <svg-icon icon-class="sq3_icon" />
          </template>
          <template #finish-icon>
            <svg-icon icon-class="sq3_icon" />
          </template>
        </van-step>
        <van-step>
          <h3 :style="failed ? 'color:#F61423' : ''">
            {{ failed ? "到账失败" : "到账成功" }}
          </h3>
          <p>
            {{
              failed
                ? info && info.reason
                  ? `（${info.reason}）`
                  : ""
                : "工作日提现最晚次日到账，遇节假日顺延"
            }}
          </p>
          <template #active-icon>
            <svg-icon icon-class="failure_icon" v-if="failed" />
            <svg-icon icon-class="sq2_icon" v-else />
          </template>
        </van-step>
      </van-steps>
      <van-button
        round
        color="#009A4D"
        block
        @click="$router.replace('capitalAccount')"
        >完成</van-button
      >
    </main>
  </div>
</template>

<script>
import { formatDate } from "@/utils/validate";
export default {
  name: "withdrawal-status",

  data() {
    return {
      info: null,
      active: 0,
      failed: false,
    };
  },
  created() {
    this.withdrawalQuery();
  },
  methods: {
    async withdrawalQuery() {
      var loadingToast = this.Toast.loading({
        message: "加载中....",
        forbidClick: true,
        duration: 0,
      });
      await this.$API.user
        .withdrawalQuery({
          object: {
            bankType: 2,
            outOrderNo: this.$route.query.outOrderNo,
          },
        })
        .then((res) => {
          if (res.data.code !== "SUCCESS") throw Error(res.data.msg);
          this.info = res.data.data;
          if (this.info.status === "processing") {
            this.active = 1;
          } else if (this.info.status === "succeeded") {
            this.active = 2;
          } else if (this.info.status === "failed") {
            this.active = 2;
            this.failed = true;
          }
          this.info.createdDate = formatDate(this.info.createdAt);
          this.info.finishedDate = formatDate(this.info.finishedAt);
          loadingToast.clear();
        })
        .catch((e) => {
          loadingToast.clear();
          this.Toast.fail(e.message);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.withdrawal_status {
  background: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 46px;
  main {
    flex: 1;
    .van-hairline::after {
      display: none;
    }
    ::v-deep .van-steps {
      margin: 50px 0;
      padding: 0 0 0 50px;
      .van-step__title {
        h3 {
          font-size: 16px;
          font-weight: normal;
        }
        p {
          font-size: 12px;
          color: #999;
        }
      }
      .van-step__circle-container {
        font-size: 22px;
        left: -25px;
      }
      .van-step__line {
        left: -25px;
      }
    }
    .van-button {
      margin: 0 auto;
      width: 85%;
    }
  }
}
</style>
